.events-container {
  padding-top: 60px;
  padding-bottom: 60px;
  background: #eee url(../images/custom/background_hotel_texture.jpg) center center no-repeat;
  background-size: cover;
  position: relative;
  opacity: calc( var(--visible-y) );
  z-index: 10;

  &:before {
    content:'';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: calc( 1 - var(--visible-y) );
    background-color: white;
    pointer-events: none;
    z-index: 100;
  }

  .column-1 {
    max-width: 850px;
  }

  .textBlockElement {

    h3 {
      font-size: 12px;
      color: $color-secondary;
      line-height: calc(28/12);
      text-transform: uppercase;
      letter-spacing: .4em;
      margin-bottom: -10px;

    }
  }

  h3.aggHeader {
    font-size: 30px;
    color: $color-primary;
    line-height: calc(36/30);
    text-transform: uppercase;
    text-align: center;
  }

  .eventAggregatorElement {
    max-width: 850px;

    .vevent {
      padding: 20px 20px 20px 0;
      margin-bottom: 4px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;


      h5 {
        padding-top: 10px;

        a {
          font-size: 21px;
          line-height: calc(24 / 21);
        }
      }

      .dateImage {
        background: transparent url(../images/custom/date_bg.svg) right top no-repeat;
        max-width: 105px;
        min-width: 105px;
        margin-bottom: 0;
      }


      .description {
        display: none;
      }

      .time {
        font-size: 13px;
      }

      .tags {
        font-size: 13px;
        font-weight: 600;
        padding: 10px 0px 0px;

        a {
          color: $color-primary;
        }
      }
    }

    .extendedOptions {
      justify-content: center;
      a {
        display: none;

        &.goToLink {
          font-size: 14px;
          font-weight: 600;
          padding: 0 20px;
          text-transform: uppercase;
          text-align: center;
          display: block;
          margin-top: 20px;
          margin-bottom: 0;
          line-height: 40px;
          min-width: 135px;

        }
      }
    }
  }

}

@media only screen and (min-width: 1024px) {

  .events-container {

    .textBlockElement {

      h3 {
        font-size: 14px;
      }
    }

    h3.aggHeader {
      font-size: 36px;
    }

    .eventAggregatorElement {

      .vevent {

        h5 a {
          font-size: 24px;
          line-height: 1;
        }

        .dateImage {
          max-width: 125px;
        }

        .time {
          font-size: 15px;
        }

        .tags {
          display: none;

        }
      }

    }
  }
}