.custom-66-33 {
  padding: 0;
  background-color: $color-primary;

  .col-md-8 {
    padding: 0;

    .heroPhotoElement {
      margin: 0;

      img {
        width: 100%;
      }
    }

  }

  .col-md-4 {
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: calc(29/14);
    color: white;
    padding: 40px 30px;
    background-image: url(../images/custom/background_purple.png);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;

    .textBlockElement {

      h3 {
        font-size: 14px;
        font-weight: 600;
        color: white;
        letter-spacing: .4em;
        line-height: calc(28/14);
        text-transform: uppercase;
        padding-bottom: 15px;
      }

      .text {
        h4:first-of-type {
          font-size: 24px;
          font-weight: 700;
          line-height: calc(30/24);
          color: white;
          text-transform: uppercase;
          padding-bottom: 15px;
          max-width: 261px;
        }

        a {
          font-size: 14px;
          line-height: 40px;
          text-transform: uppercase;
          text-align: center;
          display: inline-block;
          min-width: 130px;
          padding: 0;
          margin-right: 10px;
          margin-bottom: 10px;
          background: white;
          transition: background-color $transition-linear, color $transition-linear;

          &:hover {
            color: white;
            background-color: #4F2683;
            text-decoration: none;
          }

        }
      }
    }

  }
}

@media only screen and (min-width: 768px) {

  .custom-66-33 {
    display: flex;

    .col-md-8 {
      display: flex;

      .pageEl {
        flex: 1;
      }

      overflow: hidden;

      .heroPhotoElement {
        height: 100%;

        img {
          width: auto;
          height: 100%;
        }
      }
    }
  }

}

@media only screen and (min-width: 1024px) {

  .custom-66-33 {
    .col-md-4 {
      font-size: 16px;
      padding-left: $utility-padding-desktop;
      padding-right: $utility-padding-desktop;

      .textBlockElement {

        .text {
          h4:first-of-type {
            font-size: 30px;
            max-width: 365px;
          }
        }
      }
    }
  }

}


@media only screen and (min-width: 1500px) {

  .custom-66-33 {

    .col-md-8 {

      .heroPhotoElement {

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

}