.snFooterContainer {
  background: $color-primary url(../images/custom/background_footer.png) left bottom no-repeat;
  background-size: 350px 162px;
  color: white;
  padding-top: 35px;
  padding-bottom: 35px;
}

.custom-footer-extra {
  @include edit_ui_element($padding: 10px);

  &:empty {
    display: none;
  }

  display: flex;
  flex-flow: column;
  max-width: $content-area-width+60px;
  margin: 0 auto;
  padding-left:30px;
  padding-right: 30px;

  .textBlockElement {
    text-align: center;
  }

  .text a {
    color: white;
    font-weight: 400;
  }

}


.footer-logo {
  padding-bottom: 25px;

  img {
    max-width: 100px;
  }
}

.footer-col-1,
.footer-col-2 {
  display: none;
}

.footer-col-3 {
  padding-bottom: 10px;

  p {
    font-size: 15px;
    line-height: calc(24/15);
    margin: 0;
  }

}

.footer-social-col {
  padding-top: 18px;
  padding-bottom: 20px;

  .sn-social-media-list {
    text-align: center;
    padding-top: 5px;
  }

  .footer-social {
    padding-top: 10px;
  }

  .sn-social-media-list.light {

    &:hover {
      .sn-social-media-icon {
        background-color: rgba(white, .5);
      }
    }

    .sn-social-media-icon {
      background-color: rgba(white, 1);
      height: 25px;
      width: 25px;
      margin: 1px;
      transition: background-color $transition-linear;

      &:hover {
        background-color: rgba(white, 1);
      }

      &:before {
        color: $color-primary;
        font-size: 17px;
        line-height: 25px;
        width: 25px;
      }
    }
  }
}

.footer-btn {
  .linkElement {
    margin-top: 2px;
    margin-bottom: 3px;

    &:first-of-type {
      padding-right: 0;
    }

    h4 {
      text-align: center;

      a {
        display: inline-block;
        color: white;
        background-color: rgba(white, 0);
        min-width: 135px;
        padding: 8px 15px 4px;
        text-align: center;
        border: 1px solid white;
        transition: background-color $transition-linear, color $transition-linear;

        &:hover {
          color: $color-primary;
          background-color: rgba(white, 1);
        }

        &:after {
          display: none;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {

  .custom-footer-extra {

    flex-flow: row nowrap;
    justify-content: space-between;

    .textBlockElement {
      text-align: left;
      line-height: 1;
    }

  }

  .footer-col-1,
  .footer-col-2 {
    display: block;
  }

  .footer-logo img {
    max-width: 140px;
  }

  #siteFooter {
    padding-top: 60px;
  }

  .snFooterContainer {
    padding-top: 4%;
  }
}

#poweredByNGIN {
  display: none;
}