.user_mode {
  .textblock-image-bg {
    position: relative;
    padding: 50px 15px;
    opacity: calc( var(--visible-y) );
    z-index: 10;

    &:before {
      content:'';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: calc( 1 - var(--visible-y) );
      background-color: white;
      pointer-events: none;
      z-index: 100;
    }

    .column {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
    }

    .textBlockElement {
      &>h3 {
        font-size: 12px;
        color: $color-secondary;
        text-transform: uppercase;
        letter-spacing: .4em;
        margin-bottom: 0;
        font-weight: 600;
      }

      .text {
        color: $color-gray-dk;

        p {
          font-size: 14px;
          line-height: calc(24 / 14);
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $color-primary;
          text-transform: uppercase;
        }

        h4 {
          font-size: 30px;
          line-height: 1;
          padding-top: 10px;
          padding-bottom: 20px;
        }
      }

      a.button-small {
        @include global-button-style;
        display: inline-block;
        margin-top: 25px;
      }
    }

    &.extra-padding {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

@media only screen and (min-width: 1024px) {

  .user_mode {
    .textblock-image-bg {
      min-height: 600px;

      .textBlockElement {
        &>h3 {
          font-size: 14px;
        }

        .text {

          p {
            font-size: 21px;
            line-height: calc(30 / 21);
          }

          h4 {
            font-size: 36px;
            line-height: 70px;
          }
        }


      }
    }
  }
}

// Custom inline table styles
.user_mode {

  .textblock-image-bg .pageEl .textBlockElement .text table {
    border: 1px solid transparent;
    max-width: 200px;

    tr {
      display: flex;
      flex-flow: column;
    }

    td {
      border: 0;
      text-align: center;
      padding-bottom: 30px;

      h4 {
        font-size: 36px;
        line-height: 43px;
        padding: 0;
      }

      p {
        font-family: $font-primary;
        font-size: 14px;
        line-height: 14px;
        color: $color-primary;
        text-transform: uppercase;
      }
    }
  }

  @media only screen and (min-width: 1024px) {

    .textblock-image-bg .pageEl .textBlockElement .text table {
      max-width: 800px;
      padding-top: 30px;

      tr {
        flex-flow: row nowrap;
      }

      td {
        flex-basis: 200px;
        max-width: 200px;
      }
    }
  }

}

.user_mode {
  .billboard.textblock-image-bg {
    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0.6;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, #000000 99%);
    }

    .textBlockElement {
      &>h3 {
        color: white;
      }

      .text {
        color: white;

        h4 {
          color: white;
        }
      }
    }
  }
}