.news-container {
  background: white url(../images/custom/background_ice_texture_purple.jpg) center bottom no-repeat;
  background-size: cover;
  padding: 60px 35px 50px;
  overflow: hidden;

  &>.pageEl {
    display: block;
    flex-basis: 100%;

    >.pageElement {
      text-align: center;
    }
  }


  // Container Title
  .textBlockElement {

    &>h3 {
      font-size: 12px;
      color: $color-secondary;
      text-transform: uppercase;
      letter-spacing: .4em;
      margin-bottom: 0;
      font-weight: 600;
    }

    .text {
      color: $color-gray-dk;

      p {
        font-size: 14px;
        line-height: calc(24 / 14);
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $color-primary;
        text-transform: uppercase;
      }

      h4 {
        font-size: 30px;
        line-height: 1;
        padding-top: 10px;
        padding-bottom: 20px;
      }
    }
  }

  // News Cards

  .slick-slide {
    &>div {
      margin: 0 15px;
    }
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 36px;
    width: 36px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;

    &:before {
      content: 'arrow_back';
      text-shadow: none;
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 36px;
      color: $color-primary;
      opacity: .15;
      /* Preferred icon size */
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      transition: opacity $transition-linear;

      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;

      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;

      /* Support for IE. */
      font-feature-settings: 'liga';
    }

    &:hover:before {
      opacity: 1;
    }

  }

  .slick-prev {
    left: -30px;
  }

  .slick-next {
    right: -30px;

    &:before {
      content: 'arrow_forward';
    }
  }



  .column {
    display: flex;
    flex-flow: row nowrap;
    padding-left: 0;
    padding-right: 0;

    .pageEl {
      background-color: white;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      margin: 0;
      padding-bottom: 30px;

      .aggregatorElement {
        display: flex;
        flex-flow: column;
        margin: 0;

        .aggHeader {
          font-size: 12px;
          font-weight: 600;
          color: $color-secondary;
          letter-spacing: .4em;
          text-transform: uppercase;
          padding: 20px 30px 20px;
          position: relative;
          &:before {
              content: '';
              position: absolute;
              background-color: $color-primary;
              height: 4px;
              width: 30px;
              left: 30px;
              top: 0;
            }

        }

        .item {
          display: flex;
          flex-flow: column;

          &>a:first-child {
            position: relative;
            height: 0;
            padding-bottom: aspect-to-percent(16, 9);
            overflow: hidden;


            img {
              width: 102%;
              transform: scale(1);
              filter: grayscale(0);
              transition: width $transition-linear, transform $transition-linear, filter $transition-linear;

            }
            &:hover{
              img {
                transform:scale(1.12);
                filter: grayscale(100%);
              }
            }
          }

          .details {
            display: flex;
            flex-flow: column;
            font-size: 12px;
            line-height: 19px;
            padding: 0 30px;
            list-style-type: none;

            h4 {
              font-size: 19px;
              font-weight: 600;
              padding-bottom: 5px;
              &:hover{
                text-decoration: underline;
              }
            }


            .dateAuthor {
              display: none;
            }

            .teaser {
              color: $color-gray-md;
              padding-bottom: 20px;
            }

            .commentsAndMore {

              .readMore {

                .button-small {
                  font-size: 14px;
                  text-align: center;
                  background-color: rgba($color-primary, 0);
                  color: $color-primary;
                  border: 1px solid $color-primary;
                  transition: background-color $transition-linear;
                  display: inline-block;
                  line-height: 29px;
                  padding: 1px 15px 0;

                  &:hover {
                    color: white;
                    background-color: $color-primary;
                  }

                }
              }
            }
          }

        }

        .extendedOptions {
          display: none;
        }
      }


    }
  }

  .linkElement:last-of-type {
    h4 {
      padding-top: 10px;

      a {
        text-align: center;
        background-color: white;
        color: $color-primary;
        border: 1px solid $color-primary;
        transition: background-color $transition-linear;
        display: inline-block;
        line-height: 39px;
        padding: 1px 15px 0;
        min-width: 130px;

        &:hover {
          color: white;
          background-color: $color-primary;
        }

        &:after {
          display: none;
        }

      }
    }

  }
}

@media only screen and (min-width: 1024px) {

  .news-container {
    padding-top: 100px;
    padding-bottom: 100px;

    .textBlockElement {
      &>h3 {
        font-size: 14px;
      }

      .text {

        p {
          font-size: 21px;
          line-height: calc(30 / 21);
        }

        h4 {
          font-size: 36px;
          line-height: 70px;
        }
      }
    }

    .column {

      .pageEl {
        min-width: 340px;

        .aggregatorElement {

          .aggHeader {
            font-size: 14px;
            letter-spacing: .5em;
          }

          .item {
            .details {
              font-size: 16px;
              line-height: 1.5;

              h4 {
                font-size: 24px;
              }

              .commentsAndMore .readMore .button-small {
                line-height: 39px;
                min-width: 130px;
              }
            }
          }
        }

      }
    }

  }
}

@media only screen and (min-width: 1400px) {
  .news-container {

    .slick-prev,
    .slick-next {

      height: 50px;
      width: 50px;


      &:before {

        font-size: 50px;

      }


    }

    .slick-prev {
      left: -60px;
    }

    .slick-next {
      right: -60px;


    }

  }

}

.edit_mode {

  .selectedNodes .nodePicker-site-name img {
    display: none;
  }
  .news-container {
    .column .pageEl {
      margin: 0 auto;
      max-width: 340px;

    .aggregatorElement .item > a:first-child {
      padding-bottom:0;
      height: auto;
      margin-left: 30px;
    }
  }
    .linkElement:last-of-type {
      text-align: center;
    }

  }
}