.user_mode {
  .main-slider-container {
    .mediaSlider {
      li.slide.flex-active-slide .media-wrapper {
        animation: zoomOut 8s;
      }
      li.slide {
        z-index: 10;
        overflow: hidden;
      }
      li.slide.clone {
        transform: scale(1.1);
        z-index: 1;
        .slide-overlay{
          transform: scale(.91);
        }
      }
    }
  }
}

@keyframes zoomOut {
  from {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}

// Add this .main-nav-offset class to the container that follows the main-slider. It adds space above the container
// so it's not underneath the topnav which is moved there on the home page in desktop view

.has-main-nav .user_mode {
  .main-nav-offset {
    margin-top: $main-nav-height;
  }
}
