// Sponsors Slider
.sponsors-slider {
  background: #fff;
  padding: 0 50px;
  min-height: 230px;

  .slick-slider {

    .slick-prev,
    .slick-next {
      height: 36px;
      width: 36px;
      top: calc(50% - 18px);
      transform: none;

      &:before {
        content: 'arrow_back';
        text-shadow: none;
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 36px;
        color: $color-primary;
        opacity: .15;
        /* Preferred icon size */
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        transition: opacity $transition-linear;

        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;

        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;

        /* Support for IE. */
        font-feature-settings: 'liga';
      }

      &:hover:before {
        opacity: 1;
      }
    }

    .slick-prev {
      left: -35px;
    }

    .slick-next {
      right: -35px;

      &:before {
        content: 'arrow_forward';
      }
    }

    .slick-slide {
      .heroPhotoElement {
        img {
          padding: 10px;
          max-height: 108px;
          max-width: 180px;
          width: auto;
          transition: transform $transition-linear;
        }
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    .slick-track {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100%;
    }
  }

  .column {
    padding: 0;
    display: flex;
    align-items: center;
  }

  .edit_mode & {
    .heroPhotoElement {
      img {
        max-height: 100px;
        width: auto;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .sponsors-slider {
    padding: 0 55px;
    min-height: 250px;
  }
}

.sponsors-container {
  max-width: 100% !important;
  overflow: hidden;

  .heroPhotoElement {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 140px;
  }
}

@media only screen and (min-width: 1024px) {
  .sponsors-container {
    .heroPhotoElement {
      min-height: 120px;
    }
  }
}