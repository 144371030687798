.pencil-banner {
  background-color: $color-primary;
  color: white;
  display: flex;
  align-items: center;

  .column-1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    text-align: right;

    p {
      font-size: 18px;
      font-weight: 700;
    }
  }

  .linkElement h4 a {
    @include global-button-style;
    background-color: rgba(white, 0);
    border-color: white;
    color: white;
    display: inline-block;
    margin-left: 45px;
    transition: color $transition-linear, background-color $transition-linear;

    &:after {
      display: none;
    }

    &:hover {
      color: $color-primary;
      background-color: rgba(white, 1);
    }
  }
}