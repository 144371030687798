.quicklinks {
    .textBlockElement {
        .text {
            ul {
                list-style: none;
                li {
                    padding-top: 1px;
                    padding-bottom: 1px;
                    a {
                        @include global-link-style;
                        @include global-link-text;
                        @include global-link-container;
                        @include global-link-icon-character;
                        @include global-link-icon-style;
                        text-transform: none;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        padding-left: 20px;
                        font-weight: 700;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}