.icon-cta {

  .textBlockElement {
    position: relative;
    padding-top: 120px;
    padding-bottom: 50px;

    &:hover {
      .leftTextImage {
        transform: translateY(-5px);
      }
    }

    &>h3:first-child,
    h4,
    h4 a {

      font-family: $font-primary;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-transform: uppercase;
      text-align: center !important;
      margin-bottom: 0;
    }

    .leftTextImage {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100%;
      min-height: 70px !important;
      max-height: 70px !important;
      margin-top: 45px;
      transition: transform $transition-linear;


      img {
        max-width: 70px;
        min-width: 70px;
        height: auto;
        margin-left: 0;
        margin-right: 0;
      }
    }

    .text {
      text-align: center;
      margin: auto;
      max-width: 280px;

      p {
        font-family: $font-primary;
        font-size: 15px;
        font-weight: 400;
        line-height: 21px;


        a {
          font-weight: 400;
          line-height: 21px;
          color: $color-gray-md;
        }
      }
    }


  }


}

.icon-cta-container {
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: hidden;

  .column {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      display: block;
      height: 1px;
      width: 170px;
      background-color: $color-gray-lt;
      bottom: 0;
      right: calc((100% - 170px) / 2);
    }

    &:last-of-type:before {
      display: none;
    }
  }
}


@media only screen and (min-width: 768px) {
  .icon-cta-container {
    padding-top: 0;
    padding-bottom: 0;

    .column {
      &:before {
        height: 170px;
        width: 1px;
        right: 0;
        top: calc((100% - 170px) / 2);
      }
    }
  }

}


@media only screen and (min-width: 1024px) {


  .icon-cta {

    .textBlockElement {
      padding-left: 16%;
      padding-right: 16%;

    }

  }

}

@media only screen and (min-width: 768px) {


  // Scrollout Settings
 .user_mode .icon-cta-container {

    .column {
      position: relative;

      &:before {
        transition: opacity 2s;
        opacity: var(--visible-y);
      }

      .icon-cta {
        position: relative;
        opacity: var(--visible-y);
        transition: transform 1s;
      }

    }

    .column-1 .icon-cta {
      transform: translateX(calc(var(--visible-y) * 150px - 150px));
    }

    .column-2 .icon-cta {
      transform: translateX(calc(var(--visible-y) * 350px - 350px));
    }

    .column-3 .icon-cta {
      transform: translateX(calc(var(--visible-y) * 650px - 650px));
    }

  }
}