$enable-global-font-smoothing: true !default;

$core-font-family: $font-primary !default;
$core-font-size: 16px !default;
$core-line-height: 1.5 !default;
$core-font-weight: 400 !default;
$core-font-color: #444 !default;

$core-paragraph-font-size: 1em !default;
$core-paragraph-margin-bottom: 1rem !default;

$core-headers-font: $font-primary !default;
$core-headers-line-height: 1.125 !default;

$core-link-color: $color-primary !default;
$core-table-font-size: 12px !default;


// Global Type Styles >>> WARNING! These will affect ALL UI elements
@if ($enable-global-font-smoothing == true){
  html{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

// Body
html, body {
  font-family: $core-font-family;
  font-size: $core-font-size;
  line-height: $core-line-height;
  font-weight: $core-font-weight;
  color: $core-font-color;
}
body{
  font-size: 100%;
  text-rendering: optimizeLegibility; // for WebKit. FF is on by default. IE? probably doesn't support it.
}

// Paragraphs
p {
  font-size: $core-paragraph-font-size;
  margin-bottom: $core-paragraph-margin-bottom;
}

// Headers
h1, h2, h3, h4, h5, h6{
  font-family: $core-headers-font;
  line-height: $core-headers-line-height;
}
h1{font-size: 48px;}
h2{font-size: 32px;}
h3{font-size: 24px;}
h4{font-size: 18px;}
h5{font-size: 16px;}
h6{font-size: 16px;}

// Lists
ul{
  list-style-type: disc;
}

// Inline
b, strong { font-weight: bold; }
i, em { font-style: italic; }
small { font-size: .75em; }
mark { background-color: yellow; }
s, strike, del { text-decoration: line-through; }
u { text-decoration: underline; }
code{
  padding: 2px 4px;
  font-size: .75em;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

// Blockquotes
blockquote {
  $border-color: $global-ui-border-color;
  $border-width: 10px;
  $offset-width: $border-width + 10px;
  font-family: $font-primary;
  font-size: 20px;
  line-height: 1.4;
  font-style: italic;
  color: $core-font-color;
  box-shadow: inset $border-width 0px $border-color;
  padding-left: $offset-width;
  padding-top: 5px;
  padding-bottom: 5px;
  p{display: inline;} // Revisit when liquid
}

// Code/Pre-formatted
code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
pre {
  display: block;
  padding: .5em 1em;
  margin: 0 0 1rem 0;
  font-size: .75em;
  color: $core-font-color;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  background-color: #f5f5f5;
  border: 1px solid $global-ui-border-color;
  border-radius: 4px;
}

// Rules
hr {
  background-color: $global-ui-border-color;
  height: $global-ui-border-size;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: none;
}

// Anchors
a {
  color: $core-link-color;
  text-decoration: none;
}

// Tables
table{
  font-size: $core-table-font-size;
}
