.user_mode {

  .custom-cta {
    height: 0;
    padding-bottom: calc(100% / 3 * 2);
    background-size: 100.5%;
    background-position: center center;
    background-color: rgba(255, 255, 255, 0);
    background-blend-mode: luminosity;
    position: relative;
    overflow: hidden;
    transition: background-size .4s linear, background-color .4s linear;

    &:before {
      content: '';
      background-color: rgba(79, 38, 131, 0.9);
      position: absolute;
      left: 106%;
      top: -30%;
      width: 250%;
      height: 250%;
      transform: rotate(30deg);
      transition: left .4s ease-in-out;
      pointer-events: none;
    }

    &:after {
      content: '';
      background: transparent url(../images/custom/plus.png) 95.5% 95% no-repeat;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity .2s linear;
      pointer-events: none;
    }

    .leftTextImage {
      display: none;
    }

    .has-main-nav & {
      &:hover {
        background-size: 110%;
        background-color: rgba(255, 255, 255, 1);

        &:before {
          left: -70%;
        }

        &:after {
          opacity: 0;
        }

        .pageElement>h3:first-child {
          &:after {
            background-color: white;
          }
        }

        .textBlockElement {
          top: 15%;
        }

        .text {
          opacity: 1;
          padding-top: 15px;
        }
      }
    }

    .pageElement>h3:first-child {
      font-size: 26px;
      line-height: .9;
      text-transform: uppercase;
      color: white;
      position: relative;

      &:after {
        content: '';
        width: 40px;
        height: 4px;
        background-color: $color-primary;
        position: absolute;
        top: -10px;
        left: 0;
        transition: background-color .4s ease-in-out;
      }
    }

    .text {
      font-family: $font-primary;
      font-size: 16px;
      line-height: calc(28/16);
      color: white;
      padding-top: 50px;
      opacity: 0;
      transition: opacity .4s ease-in-out, padding-top .4s ease-in-out;
      p {
        a {
          color: $color-white;
        }
      }
    }

    .textBlockElement {
      padding: 0 40px 30px 30px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 68%;
      transition: top .4s ease-in-out;
    }
  }

}

@media only screen and (min-width: 768px) {
  .user_mode {
    .custom-cta {
      .pageElement {
        &>h3:first-child {
          font-size: 14px;
        }
      }

      .text {
        p {
          font-size: 11px;
          line-height: 1.2;
        }
      }

      .pageEl {
        &:after {
          background-size: 10px;
        }
      }

    }
  }
}

@media only screen and (min-width: 1024px) {
  .user_mode {
    .custom-cta {
      .pageElement {
        &>h3:first-child {
          font-size: 20px;
        }
      }
      .text {
        p {
          font-size: 14px;
          line-height: 1.2;
        }
      }
    }
  }
}

@media only screen and (min-width: 1130px) {
  .user_mode {
    .custom-cta {
      .pageElement {
        &>h3:first-child {
          font-size: 24px;
        }
      }
      .text {
        p {
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }
  }
}

@media only screen and (min-width: 1330px) {
  .user_mode {
    .custom-cta {
      .pageElement {
        &>h3:first-child {
          font-size: 30px;
        }
      }
    }
  }
}


.has-mobile-nav {
  .user_mode {
    .custom-cta {
      &.cta-expand {
        background-size: 110%;
        background-color: rgba(255, 255, 255, 1);

        &:before {
          left: -70%;
        }

        &:after {
          opacity: 0;
        }

        .pageElement>h3:first-child {
          &:after {
            background-color: white;
          }
        }

        .textBlockElement {
          top: 5%;
        }

        .text {
          opacity: 1;
          padding-top: 15px;
        }
      }
    }
  }
}

.user_mode {

  .custom-cta-container-full-width {
    padding: 0;
    background-color: white;
    position: relative;

    .column {
      padding: 0 0 2px;
    }

  }

  .custom-cta-container {
    padding: 10px 20px;

    .column {
      padding: 10px 10px 0;
    }

    p {
      font-size: 14px;
    }

    .custom-cta {

      &:after {
        background: transparent url(../images/custom/plus.png) 96% 95% no-repeat;
      }
    }

  }
}

@media only screen and (min-width: 768px) {
  .user_mode {
    .custom-cta-container-full-width {
      .column {
        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 2px;
          height: 100%;
          top: 0;
          right: 0;
          background-color: white;
        }

        &:last-of-type:after {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .user_mode {
    .custom-cta-container {
      padding: 0;

      .column {
        padding: 2px 0 0;
      }

      p {
        font-size: 15px;
      }

    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 850px) {
  .user_mode {
    .custom-cta-container {
      .custom-cta {
        &:after {
          content: '';
          background: transparent url(../images/custom/plus.png) 97% 95% no-repeat;
          background-size: 12px;
        }

        .textBlockElement {
          padding: 0 15px 30px;
        }
      }

    }
  }

  .has-mobile-nav .user_mode .custom-cta-container .custom-cta.cta-expand .textBlockElement {
    top: 0;
  }
}