// Page Title (display Body Header)
$page-title-full-width: false !default;
$page-title-full-width-text: false !default;
$page-title-container-background: $content-area-background !default;
$page-title-text-color: #333 !default;
$page-title-font-size: 30px !default;
$page-title-text-case: none !default;
$page-title-font-weight: normal !default;
$page-title-text-align: null !default;

#displayBodyHeader{
  background-color: $page-title-container-background;
  padding-top: 11.55% !important;
  @media screen and (max-width: 1024px) {
    padding-top: 20.088% !important;
  }
  span{
    display: block;
    color: $page-title-text-color;
    font-size: $page-title-font-size;
    text-transform: $page-title-text-case;
    font-weight: $page-title-font-weight;
    text-align: $page-title-text-align;
    margin-top: -7%;
    z-index: 1;
    position: relative;
    @media screen and (max-width: 1024px) {
      margin-top: -8%;
      font-size: 36px;
    }
    @media screen and (max-width: $breakpoint-sm-max) {
      margin-top: -9.5%;
      font-size: 30px;
    }
  }
  &.displayBodyHeader-image{
    text-indent: 0;
    position: relative;
    span {
      color: white;
    }
    &:after {
      content: '';
      display: block;
      background: linear-gradient(to top, rgba(0,0,0,0.5) 25%, transparent);
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      position: absolute;
    }
  }
}
