.sn-media-slider {
  &:hover {
    .flex-direction-nav {
      .flex-prev {
        left: 10%;
      }

      .flex-next {
        right: 10%;
      }
    }
  }

  .slider {
    font-family: $font-primary;
    font-weight: 500;

    .slide-title {
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 6px;
      text-shadow: none;
      margin: 0 0 30px 0;

      &:after {
        content: '';
        display: block;
        height: 4px;
        width: 50px;
        background-color: $color-primary;
        margin: 5px auto 0;
      }
    }

    .slide-description {
      font-size: 60px;
      font-weight: 700;
      line-height: 1;
      text-transform: uppercase;
      text-shadow: none;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      margin-bottom: 0;

    }
  }

  .slider.bottom-center-paging {
    margin: 0;

    .slider-pagination,
    .slider-pagination-numbers {
      bottom: 60px;

      &.dashed {
        .paging-item {
          width: 100px;
          background: white;
          margin-left: 3px;
          margin-right: 3px;
          height: 25%;

          &.flex-active {
            background: $color-primary;
            height: 50%;
          }
        }
      }
    }
  }

  .slides .slide .slide-overlay {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%);
    padding: 5% 15% 11%;

  }

  .flex-direction-nav {
    a {
      width: 60px;

      &:before {
        content: 'arrow_back';
        text-shadow: none;
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 60px;
        /* Preferred icon size */
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;

        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;

        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;

        /* Support for IE. */
        font-feature-settings: 'liga';
      }

      &.flex-next:before {
        content: 'arrow_forward';
      }
    }
  }

}

@media only screen and (max-width: 499px) {
  .sn-media-slider {

    &:hover {
      .flex-direction-nav {

        .flex-prev {
          left: 5px;
        }

        .flex-next {
          right: 5px;
        }

      }

    }

    .slider.sn-breakpoint-lt-500,
    .sn-media-slider .slider {

      .slide-title {
        font-size: 80%;
        margin: 0 0 10px 0;

        &:after {
          height: 3px;
          width: 40px;
          margin: 7px auto 0;
        }
      }

      .slide-description {
        font-size: 160%;
      }

      &.bottom-center-paging {

        .slider-pagination,
        .slider-pagination-numbers {
          bottom: 15px;
        }

        .slider-pagination.dashed .paging-item,
        .slider-pagination-numbers.dashed .paging-item {
          width: 40px;
          margin-left: 1px;
          margin-right: 1px;
          height: 15%;

          &.flex-active {
            height: 15%;
          }
        }
      }

    }

    .flex-direction-nav {

      a {
        width: 40px;
        transform: translate(0, 0) !important;
        opacity: 1;

        &:before {
          font-size: 40px;
        }
      }

      .flex-prev {
        left: 5px;
      }

      .flex-next {
        right: 5px;
      }

    }
  }
}

@media screen and (min-width: 500px) and (max-width: 1023px) {
  .sn-media-slider .slider {

    .slide-title {
      font-size: 16px;
      margin: 0 0 15px 0;

      &:after {
        height: 4px;
        width: 45px;
        margin: 7px auto 0;
      }
    }

    .slide-description {
      font-size: 35px;
    }

    &.bottom-center-paging {

      .slider-pagination,
      .slider-pagination-numbers {
        bottom: 20px;
      }

      .slider-pagination.dashed .paging-item,
      .slider-pagination-numbers.dashed .paging-item {
        width: 40px;
        margin-left: 1px;
        margin-right: 1px;
        height: 20%;

        &.flex-active {
          height: 20%;
        }
      }
    }

    .flex-direction-nav {

      a {
        width: 40px;
        transform: translate(0, 0) !important;
        opacity: 1;

        &:before {
          font-size: 40px;
        }
      }

      .flex-prev {
        left: 20px;
      }

      .flex-next {
        right: 20px;
      }

    }

  }
}