.has-main-nav body#home {
  &.user_mode {

    #siteContainer {
      padding: 0;
      margin-top: -$nav-title-bar-height + -$main-nav-height + -16px;
    }

    #panelTwo {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
