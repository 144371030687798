.learn-more-card {
    .textBlockElement {
        display: flex;
        flex-flow: column;
        h3 {
            order: 2;
            font-weight: 600;
            font-size: 24px;
            padding-top: 35px;
            margin-bottom: 20px;
        }
        .leftTextImage,
        .originalTextImage {
            order: 1;
            margin: 0;
        }
        .text {
            order: 3;
            p {
                font-family: $font-primary;
                color: $color-gray-md;
            }
            p:nth-of-type(2) {
                padding-top: 10px;
                a {
                    @include global-link-text;
                    @include global-link-container;
                    @include global-link-style;
                    @include global-link-icon-character;
                    @include global-link-icon-style;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    padding-left: 20px;
                    &:after {
                        color: rgba(255,255,255,0.5);
                    }
                }
            }
        }
    }
}